<template>
  <div class="content">
    <div class="icons">
      <button class="buttonSB" id="openClose-Score" @click="alteraBlocos()">
        <img class="icon-box" :src="iconOpenClose" />
      </button>
      <b-tooltip
        v-if="abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Fechar
      </b-tooltip>
      <b-tooltip
        v-if="!abreBloco && exibirTootipsConsultas"
        :target="'openClose-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Abrir
      </b-tooltip>
      <button class="buttonSB" id="goTop-Score" @click="goTop">
        <img class="icon-box" :src="iconToTop" />
      </button>
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="'goTop-Score'"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        Voltar ao Topo
      </b-tooltip>
    </div>
    <div
      class="dropdown"
      :class="{ 'dropdown--open': abreBloco }"
      v-if="resultados != null"
    >
      <HeaderBlocos :bloco="blocoScore" />
      <div class="body" :class="{ 'body--aberto': abreBloco }">
        <div class="score-box">
          <div class="col-score sb-3">
            <div class="value-box">
              <span class="value-title"> Score </span>
              <span class="value-text">
                {{ modeloSelecionado.Valor }}
              </span>
            </div>
            <div class="results-box">
              <div class="results-fixo">
                <div class="col-score sb-2">
                  <span class="result-title" :id="'BScore-descricao'"> Descrição </span>
                  <span
                    class="result-value big"
                    :class="{ bigger: resultados.especificacao.length > 40 }"
                    id="especif-output"
                    v-if="resultados.especificacao != undefined"
                  >
                    {{ resultados.especificacao }}
                  </span>
                  <span class="result-value big" v-else> ----- </span>
                </div>
                <div class="col-score sb-1">
                  <span class="result-title text-center">Origem</span>
                  <span class="result-value big">
                    <span class="box-valores" :id="'BScore-origem-P'">
                      <img
                        class="icon-valor left"
                        src="/img/superbox/mapa-riscos-prat.png"
                      />
                      <img
                        class="icon-valor"
                        src="/img/superbox/mapa-riscos-cust-cinza.png"
                      />
                    </span>
                    <!-- <span class="box-valores" :id="'BScore-origem-C'">
                    <img
                      class="icon-valor left"
                      src="/img/superbox/mapa-riscos-prat-cinza.png"
                    />
                    <img
                      class="icon-valor"
                      src="/img/superbox/mapa-riscos-cust.png"
                    />
                  </span> -->
                  </span>
                </div>
              </div>
              <div class="results-atr">
                <div
                  class="atr-linha"
                  v-for="atributo in atributosFiltrados"
                  :key="atributo.nome"
                >
                  <div class="col-score sb-2">
                    <span
                      class="result-title"
                      :id="atributo.target"
                      v-if="resultados[atributo.nome_atributo] != undefined"
                    >
                      {{ atributo.nome }}
                    </span>
                  </div>
                  <div class="col-score sb-1">
                    <span
                      class="result-value"
                      :id="atributo.target_resultado"
                      v-if="resultados[atributo.nome_atributo] != undefined"
                    >
                      {{
                        formatarValor(
                          resultados[atributo.nome_atributo],
                          atributo.nome_atributo
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-score sb-6">
            <div class="gauge-container">
              <div class="gauge-title-box">
                <span class="gauge-title"> Mensuração de Risco </span>
                <span class="gauge-subtitle">
                  Predição gerada pelo modelo estatístico
                </span>
              </div>
              <ScoreModelos :id="'score-modelos'" :score="resultados.score" :title="''" />
            </div>
          </div>
          <div class="col-score sb-3 end">
            <div class="desc-box">
              <span class="desc-title"> Interpretação </span>
              <span
                class="desc-text"
                v-if="resultados.score != '-1' && resultados.score != undefined"
              >
                O documento apresentou
                <span style="color: #2accc8; font-weight: 600">
                  {{ resultados.score }}
                </span>
                pontos
                <span
                  style="color: #2accc8; font-weight: 600"
                  v-if="resultados.rating != undefined"
                >
                  , sendo enquadrado no rating
                  {{ resultados.rating }}
                </span>
                <span v-if="resultados.classificacao != undefined">
                  , considerado de risco
                  <span style="color: #0070c0; font-weight: 600">
                    {{ resultados.classificacao }}
                  </span>
                </span>

                . <br /><br />
                <span v-if="resultados.probabilidade != undefined">
                  A taxa estimada de risco para sua respectiva faixa, entre
                  <span>
                    {{ valorFaixaScore }}
                  </span>

                  , é de
                  <span style="color: #2accc8; font-weight: 600">
                    {{ (resultados.probabilidade).toFixed(3) }}%
                  </span>

                  . Assim, cerca de
                  <span style="color: #0070c0; font-weight: 600">
                    {{ Math.ceil(resultados.probabilidade) }}
                    em cada 100 indivíduos
                  </span>

                  com mesmo perfil tendem a apresentar o evento de risco avaliado.
                </span>
              </span>
              <span
                class="desc-text"
                v-if="resultados.score == '-1' || resultados.score == undefined"
              >
                Não há informações suficientes para o cálculo adequado do score.
                <br />
                <br />
                O usuário deverá utilizar procedimentos alternativos para mensuração do
                risco.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-tooltip
      v-if="
        resultados.segmento != undefined &&
        resultados.segmento.length > 10 &&
        exibirTootipsConsultas
      "
      :target="'segmento-output'"
      triggers="hover"
      placement="bottom"
      variant="dark"
      class="tooltipTexts"
      boundary="window"
    >
      {{ resultados.segmento }}
    </b-tooltip>
    <b-tooltip
      v-if="
        resultados.especificacao != undefined &&
        resultados.especificacao > 60 &&
        exibirTootipsConsultas
      "
      :target="'especif-output'"
      triggers="hover"
      placement="bottom"
      variant="dark"
      class="tooltipTexts"
      boundary="window"
    >
      {{ resultados.especificacao }}
    </b-tooltip>
    <div v-for="(tt, i) in tooltipsBlocoScoreFiltrado" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="right"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { mapWritableState, mapActions } from "pinia";
import HeaderBlocos from "@/components/Modelos/HeaderBlocos.vue";
import ScoreModelos from "@/components/Visuais/ScoreModelos.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "BlocoScore",

  components: { HeaderBlocos, ScoreModelos },

  props: {
    listaBlocos: Array,
    // valorCPF: String,
    resultados: Object,
    modeloSelecionado: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      iconToTop: "/img/superbox/icon-totop.png",
      abreBloco: false,

      listaAtributos: [
        {
          nome: "Segmento",
          nome_atributo: "segmento",
          target: "BScore-segmento",
          target_resultado: "segmento-output",
        },
        {
          nome: "Taxa estimada na faixa",
          nome_atributo: "probabilidade",
          target: "BScore-taxa",
          target_resultado: "",
        },
        {
          nome: "Rating",
          nome_atributo: "rating",
          target: "BScore-rating",
          target_resultado: "",
        },
        {
          nome: "Risco",
          nome_atributo: "classificacao",
          target: "BScore-risco",
          target_resultado: "",
        },
        {
          nome: "Componente MM",
          nome_atributo: "componente_mm",
          target: "BScore-compMM",
          target_resultado: "",
        },
        {
          nome: "Componente OBX",
          nome_atributo: "componente_obx",
          target: "BScore-compOBX",
          target_resultado: "",
        },
        {
          nome: "Segmento Behavior",
          nome_atributo: "segmento_behavior",
          target: "",
          target_resultado: "",
        },
        {
          nome: "Faixa de atraso",
          nome_atributo: "faixa_atraso",
          target: "",
          target_resultado: "",
        },
        {
          nome: "Data de referência",
          nome_atributo: "data_ref",
          target: "",
          target_resultado: "",
        },
        {
          nome: "Score do Behavior",
          nome_atributo: "score_behavior",
          target: "",
          target_resultado: "",
        },
        {
          nome: "Data de referência do último Behavior",
          nome_atributo: "data_ref_ultimo_behavior",
          target: "",
          target_resultado: "",
        },
      ],
    };
  },

  watch: {},

  methods: {
    formatarValor(valor, nomeAtributo) {
      if (nomeAtributo === "data_ref" || nomeAtributo === "data_ref_ultimo_behavior") {
        return this.formatarData(valor);
      }

      if (typeof valor === "number") {
        return valor.toFixed(3);
      }

      return valor;
    },
    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    goTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    alteraBlocos() {
      this.abreBloco = !this.abreBloco;
      this.alteraBloco(this.abreBloco, 0);
    },

    abreEsteBloco() {
      this.abreBloco = true;
    },

    ...mapActions(blocosSuperBoxStore, ["alteraBloco"]),
    ...mapActions(storeAlertasSuperBox, ["ativaAlerta"]),
  },

  created() {},

  computed: {
    atributosFiltrados() {
      return this.listaAtributos.filter(
        (atributo) =>
          this.resultados[atributo.nome_atributo] !== undefined &&
          this.resultados[atributo.nome_atributo] !== null
      );
    },

    blocoScore() {
      return this.listaBlocos.find((bloco) => bloco.descricao === "Avaliação por Score");
    },

    iconOpenClose() {
      if (this.abreBloco) {
        return "/img/superbox/icon-dropdown.png";
      } else {
        return "/img/superbox/icon-dropdown-abrir.png";
      }
    },

    tooltipsBlocoScore() {
      return listaTooltips.blocoScore;
    },

    tooltipsBlocoScoreFiltrado() {
      let filteredTooltips = [];

      //mudar depois, por enquanto todos são de prateleira
      var filtered = listaTooltips.blocoScore.filter((tt) => {
        if (tt.target.startsWith("BScore-origem-")) {
          if (tt.target.endsWith("P")) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      });

      var tooltipsComResultado = filtered.filter((tt) => {
        const atributoRelacionado = this.listaAtributos.find(
          (attr) => attr.target === tt.target
        );
        return (
          atributoRelacionado &&
          this.resultados[atributoRelacionado.nome_atributo] !== undefined
        );
      });

      filteredTooltips = filteredTooltips.concat(tooltipsComResultado);

      return filteredTooltips;
    },

    valorFaixaScore() {
      const score = this.resultados.score;

      if (score >= 0 && score <= 100) {
        return "0 e 100";
      } else if (score > 100 && score <= 200) {
        return "101 e 200";
      } else if (score > 200 && score <= 300) {
        return "201 e 300";
      } else if (score > 300 && score <= 400) {
        return "301 e 400";
      } else if (score > 400 && score <= 500) {
        return "401 e 500";
      } else if (score > 500 && score <= 600) {
        return "501 e 600";
      } else if (score > 600 && score <= 700) {
        return "601 e 700";
      } else if (score > 700 && score <= 800) {
        return "701 e 800";
      } else if (score > 800 && score <= 900) {
        return "801 e 900";
      } else if (score > 900 && score <= 1000) {
        return "901 e 1000";
      } else {
        return "";
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.score-box {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  cursor: default;
}

.col-score {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &.end {
    justify-content: flex-end;
  }
}

.value-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
  border-bottom: solid 1px #0070c0;

  .value-title {
    width: 100%;
    height: 40px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a69be0;
    border-radius: 10px 10px 0 0;
    color: white;
  }

  .value-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: white;
    color: #0070c0;
    font-weight: 600;
    border-radius: 0 0 10px 10px;
  }
}

.results-box {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 10px;
  font-size: 12px;
  width: 100%;

  .results-fixo {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .results-atr {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }
  .atr-linha {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .result-title {
    width: 100%;
    height: 35px;
    background-color: #d9e1f2;
    border-radius: 5px;
    padding: 3px 5px;
    cursor: default;
    line-height: 14px;
    display: flex;
    align-items: center;
  }

  .result-value {
    width: 100%;
    height: 35px;
    border: 1px solid #0070c0;
    border-radius: 10px;
    padding: 2px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    line-height: 14px;

    &.big {
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
    }

    &.bigger {
      display: -webkit-box;
      // -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    &.classif {
      line-height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
      padding: 1px 5px;
    }
  }
}

.gauge-container {
  border: 1px solid #0070c0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  margin: 0px 0 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  min-height: 320px;
}

.gauge-title-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 20px;

  .gauge-title {
    font-weight: 600;
    font-size: 24px;
    color: #0070c0;
  }
  .gauge-subtitle {
    font-weight: 400;
    font-size: 14px;
    color: #0070c0;
  }
}

.desc-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;

  .desc-title {
    width: 100%;
    height: 40px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a69be0;
    border-radius: 10px 10px 0 0;
    color: white;
  }

  .desc-text {
    width: 100%;
    background-color: white;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    border-radius: 0 0 10px 10px;
    line-height: 18px;
  }
}
.box-valores {
  line-height: 35px;
}

.icon-valor {
  &.left {
    margin-right: 10px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
